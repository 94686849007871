export const GET_ARTICLES = "GET_ARTICLES"

export interface Article {
  title: string
  url: string
  sentiment: string
  datePublished: string
  locations: string
  coordinates: string[]
}

export interface ArticleState {
  articles: Article[]
}

interface GetArticles {
  type: typeof GET_ARTICLES
  payload: Article[]
}


export type ArticleActionTypes = GetArticles
