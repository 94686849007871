import React from 'react';
import shortid from 'shortid';
import moment from 'moment';
import { Article } from '../store/article/types';

interface MapMarkerProps {
  article: Article
}

export class MapMarker extends React.PureComponent<MapMarkerProps> {

  render() {

    return (
        <div className="MapView-marker-popup">
          <h2 className="MapView-MarkerPopup-Title"><a rel="noopener noreferrer" target="_blank" href={this.props.article.url}>{this.props.article.title}</a></h2>
          <div className="MapView-MarkerPopup-PillContainer">
            <b>date published</b>
            <div className="MapView-MarkerPopup-Pill">{moment(this.props.article.datePublished).format("DD/MM/YYYY")}</div>
          </div>
          <div className="MapView-MarkerPopup-PillContainer">
            <b>sentiment</b>
            <div className="MapView-MarkerPopup-Pill">{ this.props.article.sentiment[0].toUpperCase() + this.props.article.sentiment.slice(1) }</div>
          </div>
          <div className="MapView-MarkerPopup-PillContainer">
            <b>location(s)</b> { this.props.article.locations.split(",").map((loc, i) => {
              return <div key={shortid.generate()} className="MapView-MarkerPopup-Pill"> {loc} </div>
            }) }
          </div>
        </div>
    )
  }
}
