import React from 'react';
import './Header.css'
import { connect } from "react-redux";
import { SystemState } from '../store/system/types';
import { SearchState } from '../store/search/types';
import { ArticleState } from '../store/article/types';
import { AppState } from '../store/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
const classnames = require('classnames')

interface HeaderProps {
  system: SystemState
  search: SearchState
  article: ArticleState
}

export class Header extends React.Component<HeaderProps> {
  render() {
    let headerClassName = classnames('Header', { open: this.props.system.leftSidebar.open })
    let loadingClassName = classnames('Header-loading-icon', { hide: !this.props.search.searching.status })
    let searchTermClassName = classnames('Header-searchTerms', { hide: this.props.article.articles.length === 0 })

    let searchTermString = ": "
    let maxCount = 0
    this.props.search.searchTerms.words.forEach((value, i) => {
      if (maxCount >= 3) {
        return
      }
      searchTermString += value + ", "
      maxCount += 1
    })
    searchTermString = searchTermString.trim().slice(0,-1)
    if (this.props.search.searchTerms.words.length > 3) { searchTermString += "..." }

    return (
      <header className={headerClassName}>
        <div className='Header-title-container'>
          <div id="Header-logo">hotspots.</div>
          <FontAwesomeIcon className={loadingClassName} spin icon={faCircleNotch} />
        </div>
        <div className={searchTermClassName}>
          {searchTermString}
        </div>
      </header>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  system: state.system,
  search: state.search,
  article: state.article
});

export default connect(
  mapStateToProps,
)(Header);
