import React, { createRef } from 'react';
import { KeyboardEvent } from "react";
import './MultiSearchBar.css'
import { SearchPill } from './SearchPill';
import { AppState } from '../../store/index'
import { connect } from "react-redux";
import { SearchState } from '../../store/search/types';
import { thunkGetArticles } from '../../store/article/actions'
import { updateSearchTerm, updateSearchStatus } from '../../store/search/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const classnames = require('classnames')

interface MultiSearchBarProps {
  search: SearchState
  updateSearchTerm: typeof updateSearchTerm
  updateSearchStatus: typeof updateSearchStatus
  thunkGetArticles: any
}

type MultiSearchBarState = {
  currentValue: string
  inputFocus: boolean
}

export class MultiSearchBar extends React.Component<MultiSearchBarProps, MultiSearchBarState> {
  private inputText = createRef<HTMLInputElement>()

  constructor(props: MultiSearchBarProps) {
    super(props);
    this.state = {
      currentValue: '',
      inputFocus: false
    }
  }

  componentDidMount = () => {
    const node = this.inputText.current!
    node.focus()
  }

  inputFocus = () => {
    this.setState({ inputFocus: true })
  }

  inputBlur = () => {
    this.setState({ inputFocus: false })
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let validCharacters = /^[0-9A-z\s-]*$/
    if (!e.target.value.match(validCharacters)) { return }

    this.setState({ currentValue: e.target.value })
  }

  keyPressed = (e: KeyboardEvent) => {
    if (e.key !== "Enter") { return }

    let trimmedCurrentValue = this.state.currentValue.trim()

    if (trimmedCurrentValue !== '') {
      let words = this.props.search.searchTerms.words

      // Search term already exists
      if (words.indexOf(trimmedCurrentValue) > -1) {
        this.setState({ currentValue: trimmedCurrentValue })
        return
      }

      words.push(trimmedCurrentValue)
      this.props.updateSearchTerm({ words: words })
      this.setState({ currentValue: '' })
    } else {
      if (this.props.search.searchTerms.words.length > 0 && this.state.currentValue.length === 0) {
        this.props.updateSearchStatus({ status: true })
        this.props.thunkGetArticles(this.props.search, this.notifyUnableToRetrieveArticles)
      } else {
        this.setState({ currentValue: '' })
      }
    }
  }

  removePillByBackspace = (e: KeyboardEvent) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      if (this.state.currentValue === "") {
        let words = this.props.search.searchTerms.words
        words.pop()
        this.props.updateSearchTerm({ words: words })
      }
    }
  }

  removePillByClicking = (value: string) => {
    let words = this.props.search.searchTerms.words
    words = words.filter((w, i) => {
      return w !== value
    })
    this.props.updateSearchTerm({ words: words })
  }

  notifyUnableToRetrieveArticles = () => {
    toast.error("Unable to retrieve articles...", {
      position: "bottom-right",
      hideProgressBar: true
    })
  }

  render() {
    let pillCount = 0
    let words = this.props.search.searchTerms.words
    let isSearching = this.props.search.searching.status

    let multiSearchBarClassName = classnames('MultiSearchBar', { focus: this.state.inputFocus })
    let loadingOverlayClassName = classnames('MultiSearchBar-loading-overlay', { hide: !isSearching })

    return (
      <div className={multiSearchBarClassName}>
        <div className={loadingOverlayClassName}>
          <FontAwesomeIcon className="MultiSearchBar-loading-icon" spin icon={faCircleNotch} />
          <span className="MultiSearchBar-loading-text">searching for articles</span>
        </div>

        { words.map((item) => { pillCount++; return <SearchPill customRemoveHandler={this.removePillByClicking} value={item} key={pillCount} /> }) }

        <input 
          onFocus={this.inputFocus}
          onBlur={this.inputBlur}
          onKeyDown={this.removePillByBackspace}
          onKeyPress={this.keyPressed}
          onChange={this.onChange}
          value={this.state.currentValue}
          disabled={this.props.search.searching.status}
          className="MultiSearchBar-input"
          placeholder={ (words.length !== 0) ? "Hit 'Enter' again to search" : ""}
          ref={this.inputText}
        />
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  search: state.search,
})

export default connect(
  mapStateToProps,
  { updateSearchStatus, updateSearchTerm, thunkGetArticles }
)(MultiSearchBar);
