import React, { ReactNode } from 'react';
import './Button.css'

const classnames = require('classnames')

interface ButtonProps {
  children: ReactNode
  buttonType: ButtonType
  selected?: boolean
  disabled?: boolean
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

interface ButtonState {}

export enum ButtonType {
  ToggleButton,
  SubmitButton
}

export class Button extends React.Component<ButtonProps, ButtonState> {

  render() {

    let buttonClassName = classnames("Button", {
      toggle: this.props.buttonType === ButtonType.ToggleButton,
      submit: this.props.buttonType === ButtonType.SubmitButton,
      disabled: this.props.disabled,
      selected: this.props.selected
    })

    return (
      <div onClick={this.props.onClick} className={buttonClassName}>
        {this.props.children}
      </div>
    )
  }
}
