import {
  SearchState,
  SearchActionTypes,
  Sentiment,
  UPDATE_SEARCH_TERMS,
  UPDATE_SEARCH_STATUS,
  UPDATE_ADVANCED_SEARCH_TERMS,
  UPDATE_SENTIMENT,
  UPDATE_DATE_RANGE,
  UPDATE_LOCATIONS
} from './types'

const initialState: SearchState = {
  searchTerms: { words: [] },
  advancedSearchTerms: {
    sentiment: Sentiment.Negative,
    startDate: "",
    endDate: "",
    locations: []
  },
  searching: { status: false }
}

export function searchReducer(
  state = initialState,
  action: SearchActionTypes
): SearchState {
  switch (action.type) {
    case UPDATE_SEARCH_TERMS:
      return {
        searchTerms: action.payload,
        advancedSearchTerms: state.advancedSearchTerms,
        searching: state.searching
      }
    case UPDATE_SEARCH_STATUS:
      return {
        searchTerms: state.searchTerms,
        advancedSearchTerms: state.advancedSearchTerms,
        searching: action.payload
      }
    case UPDATE_ADVANCED_SEARCH_TERMS:
      return {
        searchTerms: state.searchTerms,
        advancedSearchTerms: action.payload,
        searching: state.searching
      }
    case UPDATE_SENTIMENT:
      return {
        searchTerms: state.searchTerms,
        advancedSearchTerms: {
          sentiment: action.payload,
          startDate: state.advancedSearchTerms.startDate,
          endDate: state.advancedSearchTerms.endDate,
          locations: state.advancedSearchTerms.locations
        },
        searching: state.searching
      }
    case UPDATE_DATE_RANGE:
      return {
        searchTerms: state.searchTerms,
        advancedSearchTerms: {
          sentiment: state.advancedSearchTerms.sentiment,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          locations: state.advancedSearchTerms.locations
        },
        searching: state.searching
      }
    case UPDATE_LOCATIONS:
      return {
        searchTerms: state.searchTerms,
        advancedSearchTerms: {
          sentiment: state.advancedSearchTerms.sentiment,
          startDate: state.advancedSearchTerms.startDate,
          endDate: state.advancedSearchTerms.endDate,
          locations: action.payload
        },
        searching: state.searching
      }
    default:
      return state
  }
}
