export const UPDATE_SEARCH_TERMS = "UPDATE_SEARCH_TERMS"
export const UPDATE_ADVANCED_SEARCH_TERMS = "UPDATE_ADVANCED_SEARCH_TERMS"
export const UPDATE_SEARCH_STATUS = "UPDATE_SEARCH_STATUS"
export const UPDATE_SENTIMENT = "UPDATE_SENTIMENT"
export const UPDATE_DATE_RANGE = "UPDATE_DATE_RANGE"
export const UPDATE_LOCATIONS = "UPDATE_LOCATIONS"

export enum Sentiment {
  Positive,
  Negative,
  Both,
}

export interface SearchTerms {
  words: string[]
}

export interface AdvancedSearchTerms {
  sentiment: Sentiment
  startDate: string
  endDate: string
  locations: string[]
}

export interface SearchStatus {
  status: boolean
}

export interface SearchState {
  searchTerms: SearchTerms
  advancedSearchTerms: AdvancedSearchTerms
  searching: SearchStatus
}

interface UpdateSearchTerms {
  type: typeof UPDATE_SEARCH_TERMS
  payload: SearchTerms
}

interface UpdateSearchStatus {
  type: typeof UPDATE_SEARCH_STATUS
  payload: SearchStatus
}

interface UpdateAdvancedSearchTerms {
  type: typeof UPDATE_ADVANCED_SEARCH_TERMS
  payload: AdvancedSearchTerms
}

interface UpdateSentiment {
  type: typeof UPDATE_SENTIMENT,
  payload: Sentiment
}

interface UpdateDateRange {
  type: typeof UPDATE_DATE_RANGE
  payload: {
    startDate: string,
    endDate: string
  }
}

interface UpdateLocations {
  type: typeof UPDATE_LOCATIONS,
  payload: string[]
}

export type SearchActionTypes = UpdateSearchTerms | 
  UpdateSearchStatus | 
  UpdateAdvancedSearchTerms |
  UpdateSentiment |
  UpdateDateRange |
  UpdateLocations
