import React from 'react';
import { AppState } from '../store/index'
import { connect } from "react-redux"
import './RightSidebarContent.css'
import SearchModule from './SearchModule'
import ArticlePane from './ArticlePane';

type RightSidebarContentProps = {}

export class RightSidebarContent extends React.Component<RightSidebarContentProps> {

  render() {

    return (
      <div className="RightSidebarContent">
        <SearchModule />
        <ArticlePane />
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({})

export default connect(
  mapStateToProps,
  {}
)(RightSidebarContent);
