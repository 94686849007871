import { 
  SearchTerms, 
  SearchStatus,
  Sentiment,
  AdvancedSearchTerms,
  UPDATE_SEARCH_TERMS, 
  UPDATE_SEARCH_STATUS, 
  UPDATE_ADVANCED_SEARCH_TERMS,
  UPDATE_SENTIMENT,
  UPDATE_DATE_RANGE,
  UPDATE_LOCATIONS,
  SearchActionTypes 
} from './types'

export function updateSearchTerm(searchTerms: SearchTerms): SearchActionTypes {
  return {
    type: UPDATE_SEARCH_TERMS,
    payload: searchTerms
  }
}

export function updateSearchStatus(searchStatus: SearchStatus): SearchActionTypes {
  return {
    type: UPDATE_SEARCH_STATUS,
    payload: searchStatus
  }
}

export function updateAdvancedSearchTerms(advancedSearchTerms: AdvancedSearchTerms): SearchActionTypes {
  return {
    type: UPDATE_ADVANCED_SEARCH_TERMS,
    payload: advancedSearchTerms
  }
}

export function updateSentiment(sentiment: Sentiment): SearchActionTypes {
  return {
    type: UPDATE_SENTIMENT,
    payload: sentiment
  }
}

export function updateDateRange(startDate: string, endDate: string): SearchActionTypes {
  return {
    type: UPDATE_DATE_RANGE,
    payload: {
      startDate: startDate,
      endDate: endDate
    }
  }
}

export function updateLocations(locations: string[]): SearchActionTypes {
  return {
    type: UPDATE_LOCATIONS,
    payload: locations
  }
}
