import { Article, GET_ARTICLES, ArticleActionTypes } from './types'
import { SearchState, Sentiment } from '../search/types'
import { updateSearchStatus } from '../search/actions'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AppState } from '../'

export function getArticles(articles: Article[]): ArticleActionTypes {
  return {
    type: GET_ARTICLES,
    payload: articles
  }
}

export const thunkGetArticles = (
  searchState: SearchState,
  notifyError: () => void
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {
  let apiURL = process.env.REACT_APP_API_URL

  let keywordsParam = encodeURI(searchState.searchTerms.words.reduce((prev, value) => prev += value + ",", "keywords=").slice(0, -1))
  let locationsParam = encodeURI(searchState.advancedSearchTerms.locations.reduce((prev, value) => prev += value + ",", "locations=").slice(0, -1))
  let startDateParam = encodeURI("startDate=" + searchState.advancedSearchTerms.startDate)
  let endDateParam = encodeURI("endDate=" + searchState.advancedSearchTerms.endDate)

  let sentimentParam = "sentiment="
  switch(searchState.advancedSearchTerms.sentiment) {
    case Sentiment.Positive:
      sentimentParam += "pos"
      break
    case Sentiment.Both:
      sentimentParam += "both"
      break
    default:
      sentimentParam += "neg"
  }
  sentimentParam = encodeURI(sentimentParam)

  let url = apiURL + "/straitstimes?"
  url += keywordsParam + "&" + sentimentParam
  url += (searchState.advancedSearchTerms.locations.length === 0) ? "" : "&" + locationsParam
  url += (searchState.advancedSearchTerms.startDate === "") ? "" : "&" + startDateParam
  url += (searchState.advancedSearchTerms.endDate === "") ? "" : "&" + endDateParam

  fetch(url)
    .then((response) => {
      if (!response.ok) {
        notifyError()
        throw Error(response.statusText);
      }
      return response;
    })
    .then((response) => response.json())
    .then((articles) => dispatch(getArticles(articles)))
    .catch(() => {
      notifyError()
      dispatch(updateSearchStatus({status: false}))
    })

}
