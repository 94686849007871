export const TOGGLE_LEFT_SIDEBAR = "TOGGLE_LEFT_SIDEBAR"
export const TOGGLE_RIGHT_SIDEBAR = "TOGGLE_RIGHT_SIDEBAR"
export const CHANGE_VIEW = "CHANGE_VIEW"
export const TOGGLE_HEATMAP_LAYER = "TOGGLE_HEATMAP_LAYER"

export interface LeftSidebar {
  open: boolean
}

export interface RightSidebar {
  open: boolean
}

export enum ViewType {
  MapView,
  ChartView
}

export interface SystemState {
  leftSidebar: LeftSidebar
  rightSidebar: RightSidebar
  view: ViewType
  showHeatmapLayer: boolean
}

interface ToggleLeftSidebar {
  type: typeof TOGGLE_LEFT_SIDEBAR
  payload: LeftSidebar
}

interface ToggleRightSidebar {
  type: typeof TOGGLE_RIGHT_SIDEBAR
  payload: RightSidebar
}

interface ChangeView {
  type: typeof CHANGE_VIEW
  payload: ViewType
}

interface ToggleHeatmapLayer {
  type: typeof TOGGLE_HEATMAP_LAYER
  payload: boolean
}

export type SystemActionTypes = ToggleLeftSidebar | ToggleRightSidebar | ChangeView  | ToggleHeatmapLayer
