import React from 'react';
import { AppState } from '../store/index'
import { connect } from "react-redux"
import './ArticlePane.css'
import { LocationState } from '../store/location/types'
import { ArticleState, Article } from '../store/article/types'
import { SystemState, ViewType } from '../store/system/types'
import { LatLngTuple } from 'leaflet';
import { FixedSizeList as List } from 'react-window'
import moment from 'moment';

type ArticlePaneContentProps = {
  articleState: ArticleState
  locationState: LocationState
  systemState: SystemState
}

export class ArticlePane extends React.Component<ArticlePaneContentProps> {

  shortenTitle = (t: string, max: number): string => {
    if (t.length <= max) { return t }
    return t.slice(0, max-3).trim() + "..."
  }

  generateItem = (a: Article) => {
    return (
      <React.Fragment>
        <a rel="noopener noreferrer" target="_blank" href={a.url}>{this.shortenTitle(a.title, 65)}</a>
        <div className="ArticlePane-listitem-pill-container">
          <div className="ArticlePane-listitem-pill">{ a.sentiment[0].toUpperCase() + a.sentiment.slice(1) }</div>
          <div className="ArticlePane-listitem-pill">{moment(a.datePublished).format("DD/MM/YYYY")}</div>
        </div>
      </React.Fragment>
      
    )
  }

  filterArticles = (articles: Article[]): Article[] => {
    let filteredArticles: Article[] = []
    let seenArticles = new Map()

    articles.forEach((article, _) => {
      article.coordinates.forEach((coord, _) => {
        let point: LatLngTuple = [parseFloat(coord.split(",")[0]), parseFloat(coord.split(",")[1])]
        if (this.props.locationState.mapViewBounds?.contains(point)) {
          if (!seenArticles.has(article.title)) {
            filteredArticles.push(article)
            seenArticles.set(article.title, true)
          }
        }
      })
    })

    filteredArticles.sort((a: Article, b: Article) => {
      let dateA = new Date(a.datePublished)
      let dateB = new Date(b.datePublished)
      return (dateA < dateB) ? 1 : -1
    })

    return filteredArticles
  }

  render() {
    let filteredArticles = this.filterArticles(this.props.articleState.articles)

    return (
      <div className="ArticlePane">
        { (filteredArticles.length === 0 || this.props.systemState.view === ViewType.ChartView) ? null : (
          <React.Fragment>
            <span>articles in view</span>
            <div className="ArticlePane-total">total no. of articles: {filteredArticles.length}</div>
            <List
              height={330}
              itemCount={filteredArticles.length}
              itemSize={65}
              itemData={{articles: filteredArticles}}
              width={223}
              className="ArticlePane-list"
            >
              {({ data, style, index }) => <div className="ArticlePane-listitem" style={{...style, width: 205}}>
                {this.generateItem(data.articles[index])}
              </div>}
            </List>
          </React.Fragment>
        
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  articleState: state.article,
  locationState: state.location,
  systemState: state.system
})

export default connect(
  mapStateToProps,
  {}
)(ArticlePane);
