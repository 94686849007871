import React from 'react';
import './App.css';
import Header from './components/Header'
import { Sidebar } from './components/Sidebar'
import LeftSidebarContent from './components/LeftSidebarContent';
import { connect } from "react-redux";
import { toggleLeftSidebar, toggleRightSidebar } from './store/system/actions'
import { SystemState, ViewType } from './store/system/types';
import { AppState } from './store/index'
import MapView from './components/MapView';
import ChartView from './components/ChartView'
import RightSidebarContent from './components/RightSidebarContent';
import { thunkGetLocations } from './store/location/actions'
import { ToastContainer, toast } from 'react-toastify';

interface AppProps {
  toggleLeftSidebar: typeof toggleLeftSidebar
  toggleRightSidebar: typeof toggleRightSidebar
  system: SystemState
  thunkGetLocations: any
}

class App extends React.Component<AppProps> {

  notifyErrorLoadingLocations = () => {
    toast.error("Unable to contact server...", {
      position: "bottom-right",
      hideProgressBar: true
    })
  }

  componentDidMount = () => {
    this.props.thunkGetLocations(this.notifyErrorLoadingLocations)
  }

  toggleLeftSidebar = () => {
    this.props.toggleLeftSidebar({open: !this.props.system.leftSidebar.open})
  }

  toggleRightSidebar = () => {
    this.props.toggleRightSidebar({open: !this.props.system.rightSidebar.open})
  }

  render() {
    let mainView
    if (this.props.system.view === ViewType.MapView) {
      mainView = <MapView />
    } else {
      mainView = <ChartView />
    }

    return (
      <div>
        <Sidebar key="leftSidebar" open={this.props.system.leftSidebar.open} onToggle={this.toggleLeftSidebar}>
          <LeftSidebarContent toggleSwitchChecked={(this.props.system.view === ViewType.ChartView)}/>
        </Sidebar>
        <Sidebar key="rightSidebar" anchorRight open={this.props.system.rightSidebar.open} onToggle={this.toggleRightSidebar}>
          <RightSidebarContent />
        </Sidebar>
        <div className="App">
          <Header />
          {mainView}
        </div>
        <ToastContainer />
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  system: state.system
});

export default connect(
  mapStateToProps,
  { toggleLeftSidebar, toggleRightSidebar, thunkGetLocations }
)(App);
