import { combineReducers, createStore, applyMiddleware } from 'redux'
import { systemReducer } from './system/reducers'
import { searchReducer } from './search/reducers'
import { articleReducer } from './article/reducers'
import { locationReducer } from './location/reducers'
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";

const rootReducer = combineReducers({
  system: systemReducer,
  search: searchReducer,
  article: articleReducer,
  location: locationReducer
})

export type AppState = ReturnType<typeof rootReducer>

export default function configureStore() {
  const middlewares = [thunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const store = createStore(
    rootReducer,
    composeWithDevTools(middlewareEnhancer)
  )

  return store
}
