import {
  SystemState,
  SystemActionTypes,
  ViewType,
  TOGGLE_LEFT_SIDEBAR,
  TOGGLE_RIGHT_SIDEBAR,
  CHANGE_VIEW,
  TOGGLE_HEATMAP_LAYER
} from './types'

const initialState: SystemState = {
  leftSidebar: {
    open: false
  },
  rightSidebar: {
    open: true
  },
  view: ViewType.MapView,
  showHeatmapLayer: true
}

export function systemReducer(
  state = initialState,
  action: SystemActionTypes
): SystemState {
  switch (action.type) {
    case TOGGLE_LEFT_SIDEBAR:
      return {
        leftSidebar: action.payload,
        rightSidebar: { open: state.rightSidebar.open },
        view: state.view,
        showHeatmapLayer: state.showHeatmapLayer
      }
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        leftSidebar: { open: state.leftSidebar.open },
        rightSidebar: action.payload,
        view: state.view,
        showHeatmapLayer: state.showHeatmapLayer
      }
    case CHANGE_VIEW:
      return {
        leftSidebar: { open: state.leftSidebar.open },
        rightSidebar: { open: state.rightSidebar.open },
        view: action.payload,
        showHeatmapLayer: state.showHeatmapLayer
      }
    case TOGGLE_HEATMAP_LAYER: 
      return {
        leftSidebar: { open: state.leftSidebar.open },
        rightSidebar: { open: state.rightSidebar.open },
        view: state.view,
        showHeatmapLayer: action.payload
      }
    default:
      return state
  }
}
