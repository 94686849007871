import React, { createRef } from 'react'
import ReactDOMServer from 'react-dom/server';
import './MapView.css'
import { AppState } from '../store/index'
import { connect } from "react-redux"
import { ArticleState } from '../store/article/types'
import { LocationState } from '../store/location/types'
import { SystemState } from '../store/system/types'
import { updateSearchStatus } from '../store/search/actions'
import { setMapViewBounds } from '../store/location/actions'
import { Map, TileLayer } from 'react-leaflet'
import L, { LatLngTuple, LeafletEvent } from 'leaflet'
import HeatmapLayer from 'react-leaflet-heatmap-layer'
import 'leaflet.markercluster'
import 'react-leaflet-markercluster/dist/styles.min.css'
import shortid from  "shortid"
import { MapMarker } from './MapMarker'

type MapViewProps = {
  articleState: ArticleState
  locationState: LocationState
  systemState: SystemState
  setMapViewBounds: typeof setMapViewBounds
  updateSearchStatus: typeof updateSearchStatus
}

type MapViewState = {
  showHeatmapLayer: boolean
  markerPoints: any[]
  heatmapPoints: LatLngTuple[]
  locationsDict: { [value: string] : LatLngTuple }
}

type HeatmapPoint = {
  point: LatLngTuple
  count: number
}

export class MapView extends React.Component<MapViewProps, MapViewState> {
  private map = createRef<any>()
  private markerClusterGroupOptions: L.MarkerClusterGroupOptions = {
    removeOutsideVisibleBounds: true,
    singleMarkerMode: true
  }
  private markers = new L.MarkerClusterGroup()

  constructor(props: MapViewProps) {
    super(props);
    this.state = {
      showHeatmapLayer: true,
      markerPoints: [],
      heatmapPoints: [],
      locationsDict: {}
    }
  }

  handleMapMoveend = (e: LeafletEvent) => {
    this.props.setMapViewBounds(e.target.getBounds())
  }

  getHeatMapPoints = ():  any => {
    let heatmapPoints: HeatmapPoint[] = []
    let articles = this.props.articleState.articles

    // Get the count of each unique coordinate
    articles.forEach((article, _) => {
      article.coordinates.forEach((coord, _) => {
        let point: LatLngTuple = [parseFloat(coord.split(",")[0]), parseFloat(coord.split(",")[1])]

        for (let i = 0; i < heatmapPoints.length; i++) {
          if (heatmapPoints[i].point[0] === point[0] && heatmapPoints[i].point[1] === point[1]) {
            heatmapPoints[i].count += 1
            break
          }
        }

        heatmapPoints.push({ point: point, count: 1 })
      })
    })

    // Get the highest count of coordinate
    let highestCount = Math.max.apply(Math, heatmapPoints.map((value, _) => { return value.count }))

    let heatmapLayer = null
    heatmapLayer = <HeatmapLayer
      points={heatmapPoints}
      blur={35}
      latitudeExtractor={(m: HeatmapPoint) => m.point[0]}
      longitudeExtractor={(m: HeatmapPoint) => m.point[1]}
      intensityExtractor={(m: HeatmapPoint) => { return m.count / highestCount; }}
      max={0.4}
    />

    return heatmapLayer
  }

  showMarkerPoints = () => {
    const mapNode = this.map.current!
    this.markers.clearLayers()
    this.markers = L.markerClusterGroup(this.markerClusterGroupOptions)
    this.props.articleState.articles.forEach((a) => {
      a.coordinates.forEach((c) => {
        let point: LatLngTuple = [parseFloat(c.split(",")[0]), parseFloat(c.split(",")[1])]
        let m = L.marker(point).bindPopup(ReactDOMServer.renderToStaticMarkup(<MapMarker key={shortid.generate()} article={a} />))
        this.markers.addLayer(m)
      })
    })
    if (mapNode) { mapNode.leafletElement.addLayer(this.markers) }
  }

  componentDidMount = () => {
    const mapNode = this.map.current!
    this.props.setMapViewBounds(mapNode.leafletElement.getBounds())

    if (!this.props.systemState.showHeatmapLayer) {
      this.showMarkerPoints()
    }
  }

  shouldComponentUpdate = (nextProps: MapViewProps, nextState: MapViewState) => {
    // Stop this map component from re-rendering when sidebars are toggled.
    return (nextProps.systemState.leftSidebar.open === this.props.systemState.leftSidebar.open &&
      nextProps.systemState.rightSidebar.open === this.props.systemState.rightSidebar.open &&
      nextProps.locationState.mapViewBounds === this.props.locationState.mapViewBounds)
  }

  componentDidUpdate = () => {
    // Remove loading indicators only when updating is done.
    this.props.updateSearchStatus({status: false})
  }

  median = (arr: number[]) => {
    const mid = Math.floor(arr.length / 2),
      nums = [...arr].sort()
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
  }

  render() {
    const defaultPosition: LatLngTuple = [1.3521, 103.8198]
    const mapNode = this.map.current!

    let heatmapLayer = null
    if (this.props.systemState.showHeatmapLayer) {
      // Clear markers
      if (mapNode) { mapNode.leafletElement.removeLayer(this.markers) }
      heatmapLayer = this.getHeatMapPoints()
    } else {
      this.showMarkerPoints()
    }

    return (
      <div className="MapView">
        {/* Themes can be found here: https://leaflet-extras.github.io/leaflet-providers/preview/ */}
        <Map 
          ref={this.map}
          center={defaultPosition} 
          zoom={12}
          maxZoom={16}
          zoomControl={false} 
          preferCanvas={false} 
          onMoveend={(e: LeafletEvent) => { return this.handleMapMoveend(e) }}
        >
          <TileLayer
            // url="https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
            attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
            detectRetina={true}
            maxZoom={16}
            minZoom={11}
            className="MapView-heatmap-layer"
          />
          { heatmapLayer }
        </Map>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  articleState: state.article,
  locationState: state.location,
  systemState: state.system
})

export default connect(
  mapStateToProps,
  { setMapViewBounds, updateSearchStatus }
)(MapView);
