import React, { ReactNode } from 'react';
import './Sidebar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const classnames = require('classnames')

type SidebarProps = { 
  children: ReactNode
  anchorRight: boolean
  overlay: boolean
  open: boolean
  onToggle: () => void
}

export class Sidebar extends React.Component<SidebarProps> {
  static defaultProps = {
    anchorRight: false,
    overlay: false
  }

  render () {
    let overlay;
    if (this.props.overlay) {
      let overlayClassName = classnames('Sidebar-panel-overlay', { open: this.props.open, anchorRight: this.props.anchorRight })
      overlay = <div onClick={() => this.props.onToggle()} className={overlayClassName}></div>
    }

    let sideBarPanelClassName = classnames('Sidebar-panel', { open: this.props.open, anchorRight: this.props.anchorRight })
    let sideBarPanelButtonClassName = classnames('Sidebar-panel-button', { open: this.props.open })
    let sideBarPanelButtonArrowClassName = classnames('Sidebar-panel-button-arrow', { open: this.props.anchorRight ? !this.props.open : this.props.open })

    return (
      <div className={sideBarPanelClassName}>
        <div className='Sidebar-panel-items'>{this.props.children}</div>
        <div onClick={() => this.props.onToggle()} className={sideBarPanelButtonClassName}>
          <div className={sideBarPanelButtonArrowClassName}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
        {overlay}
      </div>
    )
  }
}
