import React from 'react';
import './ChartView.css'
import { AppState } from '../store/index'
import { connect } from "react-redux";
import { ArticleState } from '../store/article/types'
import { LocationState } from '../store/location/types'
import { updateSearchStatus } from '../store/search/actions'
import { BarChart, Bar, XAxis, YAxis, Tooltip, LineChart, Line, CartesianGrid } from 'recharts'
var moment = require('moment')
var createReactClass = require('create-react-class')

type ChartViewProps = {
  articleState: ArticleState
  locationState: LocationState
  updateSearchStatus: typeof updateSearchStatus
}

interface DataPair {
  name: string
  count: number
}

const CustomLabelList = createReactClass({
  render() {
    const {x, y, stroke, value} = this.props

    return (
      <text x={x} y={y} dx={8} dy={-5} height={50} fontSize={13} fill={stroke} textAnchor="middle">{value}</text>
    )
  }
})

const CustomAxisTick = createReactClass({
  render() {
    const {x, y, payload} = this.props

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={3} dx={-1} textAnchor="end" fontSize={15} fill="#000" transform="rotate(-90)">{payload.value}</text>
      </g>
    )
  }
})

export class ChartView extends React.Component<ChartViewProps> {

  getByLocationData = () => {
    let locationLookup: {[value: string]: string} = {}
    let locationCount: {[value: string]: number} = {}
    this.props.locationState.locations.forEach((l) => {
      locationLookup[l.value] = l.planningArea
      locationCount[l.planningArea] = 0
    })
    this.props.articleState.articles.forEach((a) => {
      a.locations.split(",").forEach((l) => {
        locationCount[locationLookup[l]] += 1
      })
    })

    let locationCountArray: DataPair[] = []
    for (const [key, value] of Object.entries(locationCount)) {
      locationCountArray.push({ name: key.toLowerCase(), count: value })
    }

    locationCountArray.sort((a: DataPair, b: DataPair): number => {
      return b.count - a.count
    })

    return locationCountArray
  }

  getByYearData = () => {
    let currentYear = moment().year()
    let yearCount: {[value: string]: number} = {}
    for (let i = 2010; i <= currentYear; i++) {
      yearCount[i.toString()] = 0
    }

    this.props.articleState.articles.forEach((a) => {
      let year = a.datePublished.split("-")[0]
      if (parseInt(year) >= 2010 && parseInt(year) <= currentYear) {
        yearCount[year] += 1
      }
    })

    let yearCountArray: DataPair[] = []
    for (const [key, value] of Object.entries(yearCount)) {
      yearCountArray.push({ name: key, count: value })
    }

    return yearCountArray
  }

  componentDidUpdate = () => {
    this.props.updateSearchStatus({status: false})
  }

  render() {
    let byLocationData = this.getByLocationData().slice(0, 20)
    let byYearData = this.getByYearData()

    return (
      <div className="ChartView">
        <div className="ChartView-Container">
          <span>articles by location</span>
          <div className="ChartView-Chart">
            <BarChart
              width={700}
              height={400}
              data={byLocationData}
              margin={{
                top: 20, right: 30, left: 20, bottom: 5,
              }}>
              <Bar barSize={15} radius={[5, 5, 0, 0]} dataKey="count" fill="#000000" label={<CustomLabelList />} />
              <XAxis stroke="#000" interval={0} dataKey="name" height={100} tick={<CustomAxisTick />} />
              <YAxis tick={{fill: '#000', fontSize: 15}} stroke="#000" />
            </BarChart>
          </div>
        </div>

        <div className="ChartView-Container">
          <span>articles by year</span>
          <div className="ChartView-Chart">
          <LineChart width={700} height={250} data={byYearData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis stroke="#000" tick={{fill: '#000', fontSize: 15}} dataKey="name" />
            <YAxis stroke="#000" tick={{fill: '#000', fontSize: 15}} />
            <Tooltip />
            <Line type="monotone" dataKey="count" stroke="#000000" />
          </LineChart>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  articleState: state.article,
  locationState: state.location
})

export default connect(
  mapStateToProps,
  { updateSearchStatus }
)(ChartView);
