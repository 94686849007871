import { Location, GET_LOCATIONS, SET_MAPVIEW_BOUNDS, LocationActionTypes } from './types'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AppState } from '../'
import { LatLngBounds } from 'leaflet'

export function setMapViewBounds(bounds: LatLngBounds): LocationActionTypes {
  return {
    type: SET_MAPVIEW_BOUNDS,
    payload: bounds
  }
}

export function getLocations(locations: Location[]): LocationActionTypes {
  return {
    type: GET_LOCATIONS,
    payload: locations
  }
}

export const thunkGetLocations = (
  notifyError: () => void
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {
  let apiURL = process.env.REACT_APP_API_URL

  fetch(apiURL + "/get_locations")
    .then((response) => {
      if (!response.ok) {
        notifyError()
        throw Error(response.statusText);
      }
      return response;
    })
    .then((response) => response.json())
    .then((locations) => dispatch(getLocations(locations)))
    .catch(() => {
      notifyError()
    })

}
