import {
  ArticleActionTypes,
  ArticleState,
  GET_ARTICLES,
} from './types'

const initialState: ArticleState = {
  articles: []
}

export function articleReducer(
  state = initialState,
  action: ArticleActionTypes
): ArticleState {
  switch (action.type) {
    case GET_ARTICLES:
      return {
        articles: action.payload
      }
    default:
      return state
  }
}
