import React from 'react';
import './SearchModule.css'
import MultiSearchBar from './MultiSearchBar/MultiSearchBar';
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Button, ButtonType } from './Button'
import { Sentiment } from '../store/search/types'
import { AppState } from '../store/index'
import { connect } from "react-redux";
import { SearchState } from '../store/search/types';
import { LocationState } from '../store/location/types';
import { updateAdvancedSearchTerms, updateSentiment, updateDateRange, updateLocations } from '../store/search/actions';
import { thunkGetArticles } from '../store/article/actions'
import { updateSearchStatus } from '../store/search/actions'
import { MultiSelect } from './MultiSelect'
import { SearchPill } from './MultiSearchBar/SearchPill'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'

interface SearchModuleProps {
  search: SearchState
  location: LocationState
  updateAdvancedSearchTerms: typeof updateAdvancedSearchTerms
  updateSentiment: typeof updateSentiment
  updateDateRange: typeof updateDateRange
  updateLocations: typeof updateLocations
  updateSearchStatus: typeof updateSearchStatus
  thunkGetArticles: any
}

type SearchModuleState = {
  advancedSearchOpen: boolean
}

interface AdvancedSearchPillTemplate {
  value: string
  removeFunc: () => void
  key: string
}

export class SearchModule extends React.Component<SearchModuleProps, SearchModuleState> {
  constructor(props: SearchModuleProps) {
    super(props);
    this.state = {
      advancedSearchOpen: false
    }
  }

  openAdvancedSearch = () => {
    this.setState({ advancedSearchOpen: true })
  }

  closeAdvancedSearch = () => {
    this.setState({ advancedSearchOpen: false })
  }

  updatePosSentiment = () => {
    let currentSent = this.props.search.advancedSearchTerms.sentiment
    let sent = (currentSent === Sentiment.Positive) ? Sentiment.Both : Sentiment.Positive
    this.props.updateSentiment(sent)
  }

  updateNegSentiment = () => {
    let currentSent = this.props.search.advancedSearchTerms.sentiment
    let sent = (currentSent === Sentiment.Negative) ? Sentiment.Both : Sentiment.Negative
    this.props.updateSentiment(sent)
  }

  updateStartDate = (startDate: Date) => {
    if (startDate) {
      this.props.updateDateRange(
        moment(startDate).format("DD/MM/YYYY"),
        this.props.search.advancedSearchTerms.endDate
      )
    }
  }

  updateEndDate = (endDate: Date) => {
    if (endDate) {
      this.props.updateDateRange(
        this.props.search.advancedSearchTerms.startDate,
        moment(endDate).format("DD/MM/YYYY")
      )
    }
  }

  onMultiSearchSubmit = (value: string) => {
    if (value && value !== "") {
      let locations = this.props.search.advancedSearchTerms.locations
      if (locations.includes(value)) { return }
      locations.push(value)

      this.props.updateLocations(locations)
    }
  }

  clearSentimentFilter = () => {
    this.props.updateSentiment(Sentiment.Both)
  }

  clearStartDateFilter = () => {
    this.props.updateDateRange(
      "",
      this.props.search.advancedSearchTerms.endDate
    )
  }

  clearEndDateFilter = () => {
    this.props.updateDateRange(
      this.props.search.advancedSearchTerms.startDate,
      ""
    )
  }

  clearLocationsFilter = (location: string) => {
    let locations = this.props.search.advancedSearchTerms.locations
    locations = locations.filter((value, i) => {
      return value !== location
    })
    this.props.updateLocations(locations)
  }

  clearAllFilters = () => {
    this.props.updateAdvancedSearchTerms({
      sentiment: Sentiment.Negative,
      startDate: "",
      endDate: "",
      locations: [],
    })
  }

  submitSearch = () => {
    if (this.props.search.searchTerms.words.length > 0) {
      this.props.updateSearchStatus({ status: true })
      this.props.thunkGetArticles(this.props.search, this.notifyUnableToRetrieveArticles)
    }
  }

  notifyUnableToRetrieveArticles = () => {
    toast.error("Unable to retrieve articles...", {
      position: "bottom-right",
      hideProgressBar: true
    })
  }

  render() {
    let sentiment = this.props.search.advancedSearchTerms.sentiment
    let posSelected = (sentiment === Sentiment.Positive)
    let negSelected = (sentiment === Sentiment.Negative)
    if (sentiment === Sentiment.Both) {
      posSelected = false
      negSelected = false
    }

    let startDateString = this.props.search.advancedSearchTerms.startDate
    let endDateString = this.props.search.advancedSearchTerms.endDate
    let startDate = (startDateString) ? moment(startDateString, "DD/MM/YYYY").toDate() : null
    let endDate = (endDateString) ? moment(endDateString, "DD/MM/YYYY").toDate() : null
    let locations = this.props.search.advancedSearchTerms.locations

    let advancedSearchOptionsPills: AdvancedSearchPillTemplate[] = []
    if (sentiment !== Sentiment.Both) {
      advancedSearchOptionsPills.push({
        value: (sentiment === Sentiment.Positive) ? "Positive" : "Negative",
        removeFunc: this.clearSentimentFilter,
        key: "sentiment"
      })
    }
    if (startDateString !== "") {
      advancedSearchOptionsPills.push({
        value: "From: "+startDateString,
        removeFunc: this.clearStartDateFilter,
        key: "startDate"
      })
    }
    if (endDateString !== "") {
      advancedSearchOptionsPills.push({
        value: "To: "+endDateString,
        removeFunc: this.clearEndDateFilter,
        key: "endDate"
      })
    }
    locations.forEach((location, i) => {
      advancedSearchOptionsPills.push({
        value: location,
        removeFunc: () => { this.clearLocationsFilter(location) },
        key: "location-"+i
      })
    })

    return (
      <div className="SearchModule">
        <Modal 
          isOpen={this.state.advancedSearchOpen}
          contentLabel='Advanced Search'
          onRequestClose={this.closeAdvancedSearch}
          shouldCloseOnOverlayClick={true}
          className="SearchModule-modal"
          overlayClassName="SearchModule-modal-overlay"
        >
          <div className="SearchModule-modal-close-div">
            <FontAwesomeIcon className="SearchModule-modal-close-button" onClick={this.closeAdvancedSearch} icon={faTimes} />
          </div>
          <div className="SearchModule-modal-multisearchbar">
            <MultiSearchBar />
          </div>
          <div className="SearchModule-modal-search-options-pills">
            <div className="SearchModule-modal-search-options-pills-container">
              { advancedSearchOptionsPills.map((opt, i) => { return <SearchPill large key={opt.key} value={opt.value} customRemoveHandler={opt.removeFunc} /> }) }
            </div>
            <span onClick={this.clearAllFilters} className="SearchModule-modal-search-options-pills-clear">reset filters</span>
          </div>
          <span className="SearchModule-modal-filterby-title">filter by</span>
          <div className="SearchModule-modal-filterby-sentiment">
            <span>sentiment</span>
            <Button onClick={this.updatePosSentiment} buttonType={ButtonType.ToggleButton} selected={posSelected}><i>Positive</i></Button>
            <Button onClick={this.updateNegSentiment} buttonType={ButtonType.ToggleButton} selected={negSelected}><i>Negative</i></Button>
          </div>
          <div className="SearchModule-modal-filterby-daterange">
            <span>date</span>
            <DatePicker 
              selected={startDate} 
              dateFormat="dd/MM/yyyy"
              selectsStart
              onChange={this.updateStartDate}
              startDate={startDate}
              endDate={endDate}
              className="SearchModule-modal-range-picker"
              placeholderText='Start date'
            />
            <DatePicker
              selected={endDate}
              onChange={this.updateEndDate}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="dd/MM/yyyy"
              className="SearchModule-modal-range-picker"
              placeholderText='End date'
            />
          </div>
          <div className="SearchModule-modal-filterby-locations">
            <span>locations</span>
            <MultiSelect 
              options={this.props.location.locations.map((value, i) => { return value.value })} 
              onSubmit={this.onMultiSearchSubmit} 
              placeholderMessage="Search for a location..."
              noOptionsMessage="No such location."
            />
          </div>
          <div className="SearchModule-modal-submit-button-container">
            <Button disabled={this.props.search.searchTerms.words.length === 0 || this.props.search.searching.status}
              onClick={this.submitSearch} buttonType={ButtonType.SubmitButton}>
                Submit
            </Button>
          </div>
        </Modal>

        <span className="SearchModule-title">search</span>
        <MultiSearchBar />
        <span onClick={this.openAdvancedSearch} className="SearchModule-advance-search-link">advanced search options</span>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  search: state.search,
  location: state.location
});

export default connect(
  mapStateToProps,
  { updateAdvancedSearchTerms, updateSentiment, updateDateRange, updateLocations, updateSearchStatus, thunkGetArticles }
)(SearchModule);
