import { LeftSidebar, RightSidebar, ViewType, TOGGLE_HEATMAP_LAYER, TOGGLE_LEFT_SIDEBAR, TOGGLE_RIGHT_SIDEBAR, CHANGE_VIEW, SystemActionTypes } from './types'
import { ThunkAction } from 'redux-thunk'
import { AppState } from '../'
import { Action } from 'redux'
import { updateSearchStatus } from '../search/actions'

export function toggleLeftSidebar(leftSidebar: LeftSidebar): SystemActionTypes {
  return {
    type: TOGGLE_LEFT_SIDEBAR,
    payload: leftSidebar
  }
}

export function toggleRightSidebar(rightSidebar: RightSidebar): SystemActionTypes {
  return {
    type: TOGGLE_RIGHT_SIDEBAR,
    payload: rightSidebar
  }
}

export function changeView(view: ViewType): SystemActionTypes {
  return {
    type: CHANGE_VIEW,
    payload: view
  }
}

export const thunkToggleHeatmapLayer = (
  show: boolean
): ThunkAction<void, AppState, unknown, Action<string>> => async dispatch => {
  dispatch(updateSearchStatus({status: true}))
  // Delay to let loading indicator render first
  setTimeout(() => {
    dispatch({
      type: TOGGLE_HEATMAP_LAYER,
      payload: show
    })
  }, 100)
  
}
