import React, { createRef } from 'react';
import './MultiSelect.css'
import { KeyboardEvent } from 'react'

const classnames = require('classnames')

interface MultiSelectProps {
  options: string[]
  onSubmit: (value: string) => void
  noOptionsMessage?: string
  placeholderMessage?: string
}

interface MultiSelectState {
  currentSearchValue: string
  shownOptions: string[]
  selection: number
  mouseIsOverDropdown: boolean
  mouseIsOverOptions: boolean
  showDropDown: boolean
}

export class MultiSelect extends React.Component<MultiSelectProps, MultiSelectState> {
  private dropdownRef = createRef<HTMLUListElement>()
  private selectedRef = createRef<HTMLLIElement>()
  private inputBox = createRef<HTMLInputElement>()

  constructor(props: MultiSelectProps) {
    super(props);
    this.state = {
      currentSearchValue: '',
      shownOptions: props.options,
      selection: 0,
      mouseIsOverDropdown: false,
      mouseIsOverOptions: false,
      showDropDown: false
    }
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let validCharacters = /^[0-9A-z\s]*$/
    if (!e.target.value.match(validCharacters)) { return }

    let newShownOptions = this.props.options.filter((value, i) => {
      return value.toLowerCase().includes(e.target.value.toLowerCase())
    })

    this.setState({ 
      currentSearchValue: e.target.value,
      shownOptions: newShownOptions,
      selection: 0,
      showDropDown: true
    }, () => {
      const dropdown = this.dropdownRef.current!
      dropdown.scrollTo(0, 0)
    })
  }

  clickOnOption = (value: string) => {
    this.props.onSubmit(value)
    this.setState({
      selection: 0,
      currentSearchValue: "",
      mouseIsOverDropdown: false,
      showDropDown: false
    })
  }

  onInputFocus = () => {
    if (this.state.shownOptions.length > 0 && !this.state.mouseIsOverDropdown) {
      this.setState({ 
        selection: 0,
        showDropDown: true, 
        shownOptions: this.props.options 
      }, () => {
        if (!this.state.mouseIsOverDropdown) {
          const dropdown = this.dropdownRef.current!
          dropdown.scrollTo(0, 0)
        }
      })
    }
  }

  onInputBlur = () => {
    let mouseOverScrollbar = !this.state.mouseIsOverOptions && this.state.mouseIsOverDropdown

    let showDropDown = this.state.mouseIsOverDropdown
    this.setState({
      showDropDown: showDropDown,
      selection: 0
    }, () => {
      if (mouseOverScrollbar) {
        const inputBox = this.inputBox.current!
        inputBox.focus()
      } else {
        this.setState({ currentSearchValue: "" })
      }
    })
  }

  keyPressed = (e: KeyboardEvent) => {
    if (e.keyCode === 38) {
      e.preventDefault()
      if (this.state.selection > 0) {
        this.setState({
          selection: this.state.selection - 1,
          currentSearchValue: this.state.shownOptions[this.state.selection - 1]
        })
        const dropdownWindow = this.dropdownRef.current!
        const selectedOption = this.selectedRef.current!
        if (selectedOption.offsetTop < dropdownWindow.scrollTop + selectedOption.clientHeight * 2) {
          dropdownWindow.scrollTo(0, selectedOption.offsetTop - selectedOption.clientHeight * 2)
        }
      }
    }
    if (e.keyCode === 40) {
      if (this.state.selection < this.state.shownOptions.length - 1) {
        this.setState({
          selection: this.state.selection + 1,
          currentSearchValue: this.state.shownOptions[this.state.selection + 1]
        })
        const dropdownWindow = this.dropdownRef.current!
        const selectedOption = this.selectedRef.current!
        if (selectedOption.offsetTop >= dropdownWindow.scrollTop + selectedOption.clientHeight * 4) {
          dropdownWindow.scrollTo(0, dropdownWindow.scrollTop + selectedOption.clientHeight)
        }
      }
    }
    if (e.key === "Enter") {
      this.props.onSubmit(this.state.shownOptions[this.state.selection])
      this.setState({
        selection: 0,
        currentSearchValue: "",
        shownOptions: this.props.options,
      }, () => {
        const dropdown = this.dropdownRef.current!
        dropdown.scrollTo(0, 0)
      })
    }
    if (e.keyCode === 27) {
      e.stopPropagation()
      this.setState({
        selection: 0,
        currentSearchValue: "",
        shownOptions: this.props.options,
        showDropDown: false
      }, () => {
        const dropdown = this.dropdownRef.current!
        dropdown.scrollTo(0, 0)
        const inputBox = this.inputBox.current!
        inputBox.blur()
      })
    }
  }

  render() {
    let shownOptions = this.state.shownOptions
    let dropdownClassName = classnames("MultiSelect-dropdown", { 
      hide: !this.state.showDropDown 
    })

    return (
      <div className="MultiSelect">
        <input 
          ref={this.inputBox}
          type="text" 
          onKeyDown={this.keyPressed} 
          onChange={this.onChange} 
          value={this.state.currentSearchValue} 
          className="MultiSelect-input"
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          placeholder={(this.props.placeholderMessage || "")}
        />
        <div className="MultiSelected-dropdown-container">
          <ul 
            className={dropdownClassName} 
            ref={this.dropdownRef}
            onMouseEnter={() => { this.setState({ mouseIsOverDropdown: true }) }}
            onMouseLeave={() => { this.setState({ mouseIsOverDropdown: false }) }}
          >
            { 
              (this.state.shownOptions.length !== 0) ?
              shownOptions.map((value, i) => {
                let className = (i === this.state.selection) ? "selected" : ""
                return <li 
                  ref={(i === this.state.selection) ? this.selectedRef : null} 
                  className={className} 
                  onClick={() => this.clickOnOption(value) } 
                  onMouseEnter={() => { this.setState({ mouseIsOverOptions: true, selection: i, currentSearchValue: this.state.shownOptions[i] }) }}
                  onMouseLeave={() => { this.setState({ mouseIsOverOptions: false }) }}
                  key={i}>
                    {value}
                  </li>
              }) :
              <li>{(this.props.noOptionsMessage || "No options available...")}</li>
            }
          </ul>
        </div>
      </div>
    )
  }
}
