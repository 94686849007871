import { LatLngBounds } from "leaflet"

export const GET_LOCATIONS = "GET_LOCATIONS"
export const SET_MAPVIEW_BOUNDS = "SET_MAPVIEW_BOUNDS"

export interface Location {
  value: string
  planningArea: string
  latitude: string
  longitude: string
}

export interface LocationState {
  locations: Location[]
  mapViewBounds: LatLngBounds | null
}

interface GetLocations {
  type: typeof GET_LOCATIONS
  payload: Location[]
}

interface SetMapViewBounds {
  type: typeof SET_MAPVIEW_BOUNDS
  payload: LatLngBounds
}


export type LocationActionTypes = GetLocations | SetMapViewBounds
