import React from 'react';
import './SearchPill.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// TODO: Make this component dumb and not retrieve the search state here.

const classnames = require('classnames')

interface SearchPillProps {
  value: string
  customRemoveHandler: (value: string) => void
  large?: boolean
}

type SearchPillState = {
  focus: boolean
}

export class SearchPill extends React.Component<SearchPillProps, SearchPillState> {
  constructor(props: SearchPillProps) {
    super(props);
    this.state = {
      focus: false
    }
  }

  mouseOverClose = () => {
    this.setState({ focus: true })
  }

  mouseLeaveClose = () => {
    this.setState({ focus: false })
  }

  render() {
    let searchPillClassName = classnames('SearchPill', {
      focus: this.state.focus,
      large: this.props.large
    })

    return (
      <div className={searchPillClassName}>
        {this.props.value}
        <div 
         onMouseLeave={this.mouseLeaveClose} 
         onClick={() => { this.props.customRemoveHandler!(this.props.value) } } 
         onMouseEnter={this.mouseOverClose} 
         className="SearchPill-close-button">
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    )
  }
}
