import {
  LocationActionTypes,
  LocationState,
  GET_LOCATIONS,
  SET_MAPVIEW_BOUNDS,
} from './types'

const initialState: LocationState = {
  locations: [],
  mapViewBounds: null
}

export function locationReducer(
  state = initialState,
  action: LocationActionTypes
): LocationState {
  switch (action.type) {
    case GET_LOCATIONS:
      return {
        locations: action.payload,
        mapViewBounds: state.mapViewBounds
      }
    case SET_MAPVIEW_BOUNDS:
      return {
        locations: state.locations,
        mapViewBounds: action.payload
      }
    default:
      return state
  }
}
