import React from 'react';
import './LeftSidebarContent.css'
import Switch from 'react-switch'
import { ViewType, SystemState } from '../store/system/types'
import { thunkToggleHeatmapLayer } from '../store/system/actions'
import { AppState } from '../store/index'
import { connect } from "react-redux"
import { changeView } from '../store/system/actions'
const classname = require('classnames')

interface LeftSidebarContentProps {
  toggleSwitchChecked: boolean
  thunkToggleHeatmapLayer: any
  changeView: typeof changeView
  system: SystemState
}

export class LeftSidebarContent extends React.Component<LeftSidebarContentProps> {

  handleChange = () => {
    this.props.changeView((this.props.toggleSwitchChecked) ? ViewType.MapView : ViewType.ChartView)
  }

  toggleHeatmap = () => {
    this.props.thunkToggleHeatmapLayer(!this.props.system.showHeatmapLayer)
  }

  render() {
    let toggleSwitchClassName = classname('LeftSidebarContent-toggle-switch', 'bottom', { disabled: this.props.system.view === ViewType.ChartView })

    return (
      <div className="LeftSidebarContent">
        <span className='LeftSidebarContent-title'>view</span>
        <div className='LeftSidebarContent-toggle-switch'>
          <span>map</span>
          <Switch
            offColor="#000"
            onColor="#000"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={this.handleChange}
            checked={this.props.toggleSwitchChecked}
            className="react-switch"
          />
          <span>chart</span>
        </div>
        <div className={toggleSwitchClassName}>
          <span>marker</span>
          <Switch
            offColor="#000"
            onColor="#000"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={this.toggleHeatmap}
            checked={this.props.system.showHeatmapLayer}
            className="LeftSidebarContent-react-switch"
            disabled={this.props.system.view === ViewType.ChartView}
          />
          <span className='no-right-margin'>heatmap</span>
        </div>
        <span className='LeftSidebarContent-title'>about</span>
        <div className='LeftSidebarContent-about-text'>
          This website originated from trying to prove that Yishun is not the only crazy town in Singapore. Articles from The Straits Times were scraped and analysed for locations and negative sentiment. You will be able to visualise Singapore-related articles by location.
        </div>
        <div className="LeftSidebarContent-credits">
          created by: <a rel="noopener noreferrer" target="_blank" href="https://github.com/wongherlung">wongherlaang</a><br />
          designed by: <a rel="noopener noreferrer" target="_blank" href="https://sg.linkedin.com/in/huiyuan-zheng-56244b59">huiyuan</a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  system: state.system
});

export default connect(
  mapStateToProps,
  { changeView, thunkToggleHeatmapLayer }
)(LeftSidebarContent);
